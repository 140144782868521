$(document).ready(function () {
  $("#cv-upload-field").on("change", function () {
    $(".upload-title")[0].innerHTML = this.files[0].name;
  });

  // Start Google Map
  $("#google-map").width(($(".footer .container .row").width() / 12) * 7 - 24);

  changeMapSize = () => {
    let rowWidth = $(".footer .container .row").width();

    $("#google-map").width((rowWidth / 12) * 7 - 24);
  };

  $(document.querySelectorAll('[data-address]')).on("click", (e) => {
    document.getElementById("google-map").src = e.currentTarget.dataset.address;
  });

  window.addEventListener("resize", changeMapSize);
  // End Google Map
});
