// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require.context('/markup/img', true)
require.context('/app/assets/images', true)

import "jquery"
window.$ = $
import "../stylesheets/application"
import "/markup/css/style"
import "bootstrap-v5/dist/js/bootstrap"
import "@fortawesome/fontawesome-free/css/all"
import "./common"
import toastr from "toastr"
window.toastr = toastr
